import getSiteUrl from "./getSiteUrl";
import getSubdomainFromHost from "./getSubdomainFromHost";

/**
 * Get the main domain URL for the current environment
 * In production: https://www.ycn.org
 * In development: https://www.ycn.ddev.site
 *
 * @returns {string} The main domain URL
 */
export const getMainDomainUrl = () => {
  const isDev = process.env.NODE_ENV === "development";
  return isDev ? "https://www.ycn.ddev.site" : "https://www.ycn.org";
};

/**
 * Check if the current hostname is a subdomain
 *
 * @param {string} hostname - The hostname to check
 * @returns {boolean} True if the hostname is a subdomain
 */
export const isSubdomain = (hostname) => {
  return !!getSubdomainFromHost(hostname);
};

/**
 * Get the authentication URL for the current environment
 * This is always the main domain's auth URL
 *
 * @param {string} path - The path to append (e.g., "/signin" or "/signout")
 * @param {Object} queryParams - Optional query parameters to add to the URL
 * @returns {string} The authentication URL
 */
export const getAuthUrl = (path = "/auth/signin", queryParams = {}) => {
  const baseUrl = getMainDomainUrl();
  const url = new URL(`${baseUrl}${path}`);

  // Add query parameters
  Object.entries(queryParams).forEach(([key, value]) => {
    if (value !== undefined && value !== null) {
      url.searchParams.append(key, value);
    }
  });

  return url.toString();
};

/**
 * Format a destination URL for cross-domain redirects
 *
 * @param {string} destination - The destination path or URL
 * @param {Object} organisation - Optional organisation object for organization-specific URLs
 * @returns {string} The formatted destination URL
 */
export const formatDestinationUrl = (destination, organisation = null) => {
  // If it's already a full URL, return it as is
  if (
    destination &&
    (destination.startsWith("http://") || destination.startsWith("https://"))
  ) {
    return destination;
  }

  // If there's an organisation, use getSiteUrl to get the proper domain
  if (organisation) {
    return getSiteUrl(organisation, destination);
  }

  // Otherwise, append to the main domain
  const baseUrl = getMainDomainUrl();
  const path = destination?.startsWith("/")
    ? destination
    : `/${destination || ""}`;
  return `${baseUrl}${path}`;
};

/**
 * Get the current subdomain and determine if it's an organisation subdomain
 *
 * @param {string} hostname - The hostname to check
 * @returns {Object} Object with subdomain and isOrgSubdomain properties
 */
export const getCurrentSubdomainInfo = (hostname) => {
  const subdomain = getSubdomainFromHost(hostname);

  // Main domain (www) is not considered an organization subdomain
  const isOrgSubdomain = !!subdomain && subdomain !== "www";

  return {
    subdomain,
    isOrgSubdomain,
  };
};

export default {
  getMainDomainUrl,
  isSubdomain,
  getAuthUrl,
  formatDestinationUrl,
  getCurrentSubdomainInfo,
};
