/**
 * Extract the subdomain from a hostname
 *
 * In production: extracts 'koto' from 'koto.ycn.org' or returns null for 'www.ycn.org'
 * In development: extracts 'koto' from 'koto.ycn.ddev.site' or returns null for 'www.ycn.ddev.site'
 *
 * @param {string} hostname - The hostname to extract from
 * @returns {string|null} The subdomain or null if it's the main domain
 */
const getSubdomainFromHost = (hostname) => {
  if (!hostname) return null;

  const isDev = process.env.NODE_ENV === "development";

  // Production pattern: subdomain.ycn.org or www.ycn.org
  // Development pattern: subdomain.ycn.ddev.site or www.ycn.ddev.site
  const parts = hostname.split(".");

  if (isDev) {
    // Development environment
    // Format: subdomain.ycn.ddev.site
    if (parts.length > 3 && parts[1] === "ycn" && parts[2] === "ddev") {
      // Return null for www prefix (main site)
      return parts[0] !== "www" ? parts[0] : null;
    }
  } else {
    // Production environment
    // Format: subdomain.ycn.org
    if (parts.length > 2 && parts[1] === "ycn" && parts[2] === "org") {
      // Return null for www prefix (main site)
      return parts[0] !== "www" ? parts[0] : null;
    }
  }

  return null;
};

export default getSubdomainFromHost;
