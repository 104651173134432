const getSiteUrl = (organisation, destination = "") => {
  const isDev = process.env.NODE_ENV === "development";

  try {
    // Decode the URL before checking
    const decodedDestination = decodeURIComponent(destination);

    // If destination is a full URL, validate and return it
    if (
      decodedDestination.startsWith("https://") ||
      decodedDestination.startsWith("http://")
    ) {
      // Validate the URL
      new URL(decodedDestination);
      return decodedDestination;
    }

    let url;

    if (!isDev && process.env.NEXT_PUBLIC_VERCEL_ENV === "production") {
      // Production environment
      if (!!organisation && !!organisation?.slug) {
        url = `https://${organisation.slug}.ycn.org`;
      } else {
        url = "https://www.ycn.org";
      }
    } else {
      // Development environment (DDEV)
      if (!!organisation && !!organisation?.slug) {
        // Organization subdomain
        url = `https://${organisation.slug}.ycn.ddev.site`;
      } else {
        // Main site - use www prefix for consistency with production
        url = "https://www.ycn.ddev.site";
      }
    }

    // Add the destination path to the URL if provided
    if (!!destination) {
      // Make sure destination starts with a slash and is properly encoded
      const formattedDestination = destination.startsWith("/")
        ? destination
        : `/${destination}`;
      url = `${url}${formattedDestination}`;
    }

    // Validate the final URL
    new URL(url);
    return url;
  } catch (error) {
    console.error("Error in getSiteUrl:", error);
    // Return a safe fallback URL
    return isDev ? "https://www.ycn.ddev.site" : "https://www.ycn.org";
  }
};

export default getSiteUrl;
