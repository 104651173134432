import { signOut } from "next-auth/react";
import getSubdomainFromHost from "./getSubdomainFromHost";

/**
 * Custom sign-out function that handles cross-domain sign-outs properly
 *
 * @param {Object} options - Options for the sign-out process
 * @param {string} [options.callbackUrl] - URL to redirect to after sign-out
 * @param {boolean} [options.withRedirect=true] - Whether to redirect after sign-out
 * @returns {Promise} - The sign-out promise
 */
const customSignOut = async ({ callbackUrl, withRedirect = true } = {}) => {
  const isDev = process.env.NODE_ENV === "development";

  // Get current hostname and check if we're on a subdomain
  const hostname =
    typeof window !== "undefined" ? window.location.hostname : "";
  const subdomain = getSubdomainFromHost(hostname);
  const isSubdomain = !!subdomain;

  // Determine the main client domain based on environment
  const clientBaseUrl = isDev
    ? "https://www.ycn.ddev.site"
    : "https://www.ycn.org";

  if (isDev) {
    console.log(
      `[customSignOut] Signing out from ${hostname}, subdomain: ${subdomain}`
    );
  }

  // Sign out without redirecting initially
  const result = await signOut({ redirect: false });

  if (isDev) {
    console.log("[customSignOut] Sign-out response:", result);
  }

  if (withRedirect) {
    // Default callback is the home page of the current domain
    let effectiveCallbackUrl = callbackUrl || "/";

    // For subdomains, we might want to redirect to the main domain after sign-out
    if (isSubdomain && !callbackUrl) {
      effectiveCallbackUrl = clientBaseUrl;
    }

    if (isDev) {
      console.log(`[customSignOut] Redirecting to: ${effectiveCallbackUrl}`);
    }

    // Use window.location for cross-domain redirects
    window.location.href = effectiveCallbackUrl;
  }

  return result;
};

export default customSignOut;
